import './Join.css';

export default function Join(){
    return (
      <div className='basic_info'>
        <h2>
          Join Us!   
        </h2>
        <p>
          At Gothenburg Drama Group everyone's welcome. 
          <br/>
          If you want to improve your English, dabble in acting or would like to use your creative talents for making theatre, come join us!
          <br/>
          In our group it doesn't matter if you have experience or not, anyone can be a part of our group.
        </p>
          <br/>
        <p>
          
          We put on every production entirely by ourselves in a completely flat organization where we all work together to make something spectacular. 
          <br/>
          Everyone's ideas are welcome and we always decide together which project we’re taking on this time. 
          <br/>
        </p>
        <br/>
        <p>
        If you want to join us, or are interested in coming to our rehearsals sometime, <a href="mailto:gothenburgdrama@gmail.com">mail us</a> at gothenburgdrama@gmail.com or contact us via private message on <a href="https://www.facebook.com/GothenburgDrama">Facebook</a> or <a href="https://www.instagram.com/GothenburgDrama">Instagram</a>.
        </p>
        {/* <p> ///The old archives//
          Are you interested in joining our group? Do you have theatre experience, or are you just a person that's interested in theatre and want to join a group? 
        </p>
        <p>
          If you want to have a go at being on stage, helping out backstage or even if you purely want to spend time with English speakers and improve your English.
          
        </p> */}
        <br/>
      </div>
    );
}