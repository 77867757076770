import './Upcoming.css';
import Flyer from "../whicherposter.jpeg";

import { text } from '@fortawesome/fontawesome-svg-core';
import { AlignCenter } from 'react-feather';

export default function Upcoming(){
    return (
        <div className='basic_info_upcoming '>
        <h2>
          Upcoming Show!  
        </h2>
        <h3>"The Suspicions of Mr Whicher"</h3>
        <br/>

        <p>

        Gothenburg Drama Group proudly presents: a rendition of the stage adaptation of Kate Summerscale's gripping bestseller on a real-life Victorian mystery. 
        <br/>
        Summer 1860, an elegant country house, a young boy is found dead in an outside privy. All clues point towards the murderer being a member of the grieving household.
        <br/>
        Called to the scene is the most celebrated detective of his day, Jonathan Whicher from Scotland Yard. But this case challenges him in ways he's never been challenged before.
        <br/>
        Over twenty years later, still haunted by the case, Whicher visits the murderer. As they replay the past, they start to question the nature of truth, the desire for certainty and the possibility of redemption. 

          </p>
          <br/>
          <p>
          The curtains will be lifted in October at Dice Theater in Gothenburg. 
           Hurry up and get a ticket before they run out! 
            </p>
        <br/>
            

            <p>Go on over to <a className='upcoming_link' href='https://billetto.se/users/gothenburg-drama-group'>billetto.se</a> right now and secure yourself a night of fun!</p>
          <br/>

        <div className='img-container'>
        <img  className='image' src={Flyer} alt="flyer" />
        </div>

      </div>
    );
}