import logo from './gdgtransp1.png'
import './App.css';
import { useState, useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import About from './pages/About'
import Upcoming from './pages/Upcoming'
import Join from './pages/Join'
import BetweenProductions from './pages/BetweenProductions'
import Timeline from './components/Timeline'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTheaterMasks } from '@fortawesome/free-solid-svg-icons';
import Spotlight from './utils/Spotlight';


function App() {
  const [isExploding, setIsExploding] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  function changeBackground(e) {
    setIsExploding(true);

    // Set a timeout for a specific duration (e.g., 2000 milliseconds or 2 seconds)
    setTimeout(() => {
        // Code to execute after the timeout
        // For example, setting `setIsExploding` to false
        setIsExploding(false);
    }, 1600); // Replace 2000 with the number of milliseconds for the delay
}

  useEffect(() => {
    function handleResize() {
      
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);


  function UpcomingOrBetween(ComingUp) {
    if (ComingUp) {
      return <Upcoming/>;
    }
    return <BetweenProductions/>;
  }


  return (
    <>
    <Spotlight />
      <div className="background-container">
        <nav>
        <Navbar/>
        </nav>
        {isExploding && <ConfettiExplosion className="confetti" />}
          <section className="stage-container">
            <header className="App-header">
              <a 
                className='gdg_logo'
                onMouseEnter={changeBackground}
              >
                <Link
                      activeClass="active"
                      to="About"
                      spy={true}
                      smooth={true}
                      duration={1500}
                      >
              <img src={logo} className="hvr-grow App-logo" alt="logo" />
              </Link>         
                </a>
            </header>
          </section>
          <Element name="Upcoming" className="element">
            {/* true for upcoming otherwise false */}
            {UpcomingOrBetween(true)}
          </Element>
          <Element name="About" className="element">
            <About/>
          </Element>
          {/* Element with parralax effect like blink-impro with members?  */}
          <Element name="Join" className="element">
            <Join/>
          </Element>
          <Element name="Timeline" className="element">
            <Timeline/>
          </Element>
          <Footer/>
          <div className="light">
        </div>
      </div>
    </>
  );
}

export default App;
