import React, { useState, useEffect } from 'react';
import './Spotlight.css';

const Spotlight = () => {
    const [spotlightPosition, setSpotlightPosition] = useState({ x: -100, y: -100 });

    useEffect(() => {
        const handleMouseMove = (e) => {
            setSpotlightPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="spotlight" style={{ left: spotlightPosition.x, top: spotlightPosition.y }}></div>
    );
};

export default Spotlight;