import './About.css';

export default function About(){
    return (
        <div className='basic_info'>
        <h2>
          Who is Gothenburg Drama Group?
        </h2>
        <p>
          The Gothenburg Drama Group (GDG) is an amateur theatre company founded by the American Consulate in 1971, at which time most of the members were British ex-patriots.
          The group has amazingly continued throughout the years with an ever changing group of members. 
          Over many years we have had Teaterhuset in Mölndal as our base of operations, today we work together with Medborgarskolan for our rehearsalspace.
        </p>
        <br/>
        <p>
          The strength of GDG comes through its members and today we have people who come from all over the world and joined together by their love of fun, theatre and the English language. 
          Anyone and everyone is welcome! 
        <br/>
        </p>
        <p>
        <br/>
          The GDG has produced several successful plays at various venues in Gothenburg, including Göteborgs Dramatiska Teater, Hagateatern, Backa Kulturhus, Stadsbiblioteket, Trappan Restaurant (Folkteatern) and Teaterhuset in Mölndal. 
          Our next play which will premiere this May will be performed at Kulturlagret in Majorna.
        </p>
      </div>
    );
}