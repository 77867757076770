let timelineElements = [

  {
    id: 1,
    title: "Bedroom Farce",
    location: "Kulturlagret, Göteborg",
    description:
      "The play takes place in three different bedrooms in which get to follow a night of misscomunication and drama between four pairs.",
    date: "Spring 2023",
  },
  {
    id: 6,
    title: "The Private Ear/The Public Eye",
    location: "Teaterhuset, Mölndal",
    description:
      "This play consists of two short plays written by the award-winning playwright Peter Shaffer. It was the latest play GDG set up at Teaterhuset.",
    date: "Spring 2022",
  },
  {
    id: 1,
    title: "Murdered to Death",
    location: "Teaterhuset, Mölndal",
    description:
      "A play where a great and humorous murder mystery takes place.",
    date: "Autumn 2020",
  },
    {
      id: 3,
      title: "Arsenic and Old Lace",
      location: "Teaterhuset, Mölndal",
      description:
        "A Classic Black Comedy written by Joseph Kesselring. The play is set in Brooklyn New York in the 1940s at the home of the eccentric family – The Brewsters.",
      date: "Spring 2019",
    },
    {
      id: 4,
      title: "I hate Shakespeare",
      location: "Teaterhuset, Mölndal",
      description:
        "We hate Shakespeare! At least that's what the audience thinks until they get a rip-roaring rundown of Shakespeare's classics. With zombies, talking cows, andan appearance by Jerry Springer, I Hate Shakespeare! is a hilarious and fast-paced introduction to Shakespeare -- with a modern twist.",
      date: "Spring 2018",
    },
    {
      id: 5,
      title: "Excercises in Timing",
      location: "Teaterhuset, Mölndal",
      description:
        "3 Funny Interactions of Human Behaviour.EXERCISES IN STYLE - SURE THING - ENGLISH MADE SIMPLE",
      date: "Spring 2016",
    },
  
   
    {
      id: 1,
      title: "The Creation of Gothenburg Drama Group",
      description:
        "The love for theatre and community gave birth to Gothenburg Drama Group",
      date: "Sometime 1971",
      iconStar: true,
    },
  ];
  
  export default timelineElements;