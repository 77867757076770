import React, { useState, useEffect } from 'react';
import "./Timeline.css";
import { ReactComponent as TheaterIcon } from "../theater.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faMasksTheater,faStar } from '@fortawesome/free-solid-svg-icons'
import PrivateLogo from "../private.jpg"
import MurderedLogo from "../murdered.jpg"
import text from '../texts/texts.json';
import timelineElements from "./timelineElements";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";


export default function Timeline() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  let style = { background: "#D161A5" };
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      <h1 className="title">{text.about.main}</h1>
      <VerticalTimeline>
        {timelineElements.map((element) => {
        let currentImg;
        switch (element.img) {
          case element.img == "private":
            currentImg = PrivateLogo
            break;
        
          default:
            break;
        }
          return (
            <>
            {screenSize <= 800 ? (
              <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={style}
              icon={
                element.iconStar ? 
                  <FontAwesomeIcon icon={faStar} style={{ color: "#FFFCF4F4" }} /> 
                : 
                  <FontAwesomeIcon icon={faMasksTheater} style={{ color: "#FFFCF4F4" }} />
              }
              iconClassName="icon"
              >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p id="description">{
              element.description
              
              }</p>
  
            </VerticalTimelineElement>
            ) : (
              <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={style}
              icon={
                element.iconStar ? 
                  <FontAwesomeIcon icon={faStar} style={{ color: "#FFFCF4F4" }} /> 
                : 
                  <FontAwesomeIcon icon={faMasksTheater} style={{ color: "#FFFCF4F4" }} />
              }
              iconClassName="icon"
              >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p id="description">{
              element.description
              
              }</p>
  
            </VerticalTimelineElement>
            )}
          </>


            
          );
        })}
      </VerticalTimeline>
    </div>
  );
}
