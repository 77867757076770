import {
  Link,
  animateScroll as scroll,
} from "react-scroll";
import React from 'react';
import './Navbar.css';

const Navbar = () => {
  return (
    <div className="navbar">
      <ul className='list'>
      <Link
        activeClass="active"
        to="Upcoming"
        spy={true}
        smooth={true}
        duration={1500}>
        <li className='link hvr-bounce-in'>Upcoming Show</li>
      </Link>
      <Link
        activeClass="active"
        to="About"
        spy={true}
        smooth={true}
        duration={1500}>
        <li className='link hvr-bounce-in'>About Us</li>
      </Link>
      <Link
        activeClass="active"
        to="Join"
        spy={true}
        smooth={true}
        duration={1500}>
        <li className='link hvr-bounce-in'>Join Us!</li>
      </Link>
      <Link
        activeClass="active"
        to="Timeline"
        spy={true}
        smooth={true}
        duration={1500}>
        <li className='link hvr-bounce-in'>Past Shows</li>
      </Link>
      </ul>
    </div>
  );
};

export default Navbar;
