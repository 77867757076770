import './BetweenProductions.css';

export default function BetweenProductions(){
    return (
        <div className='basic_info'>
        <h2>
        Oh hello there! ... You're a bit early. 😅
        </h2>

        {/* <p>
          We are currently between productions. We recently closed curtains on our last production "Bedroom Farce" at kulturlagret which was success!
          If you came to see it, thank you! And if you didn't, do not fret; we will put up another show soon enough!  
          Right now we in GDG are enjoying the summer and meeting sporadically until we start our regular meetings in the autumn again. 
        </p> */}
        <p>
          We are currently between productions. We recently closed curtains on our last production  which was success!
          If you came to see our last play, "Bedroom Farce" at kulturlagret, thank you! And if you didn't, do not fret; we will put up another show the spring of 2024!  
          Right now the GDG gang is the preperations of begging the casting and rehearsals for our next project. Which we soon will send out more information about. 
        </p>

        
        <p>
        <br/>
        Do you wish to know when we have our next show or if you want to join us or help us out in any way, <a href="mailto:gothenburgdrama@gmail.com">mail us</a> at gothenburgdrama@gmail.com or contact us via private message on <a href="https://www.facebook.com/GothenburgDrama">Facebook</a> or <a href="https://www.instagram.com/GothenburgDrama">Instagram</a>.
          
        </p>
        <br/>
        <p>
          We wish you a Merry Christmas and Happy New Year!
        </p>
      </div>
    );
}